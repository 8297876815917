/**
 * @author Vahagn Lazyan.
 */

import {MultilingualString} from "@synisys/idm-crosscutting-concepts-frontend";

export function multilingualToString(multilingualString: MultilingualString | undefined): string {
    if (!multilingualString) {
        return '';
    }
    const obj: object = {};
    multilingualString.toMap().forEach((value: string, key: number) => {
        obj[key] = value;
    });

    return JSON.stringify(obj);
}

export function stringToMultilingual(json: string): MultilingualString | undefined {
    if (json === '') {
        return undefined;
    }
    const multilingualString = JSON.parse(json);
    const builder = MultilingualString.newBuilder();
    for (const key in multilingualString) {
        if (multilingualString.hasOwnProperty(key)) {
            builder.withValueForLanguage(+key, multilingualString[key]);
        }
    }
    return builder.build();
}
