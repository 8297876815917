/**
 * @author Vahagn Lazyan.
 */
import {Component} from "@angular/core";

import {BaseSettings} from "./sis-base-settings";
import {CommunicationService, ControlMetadata} from "@synisys/idm-dynamic-controls-metadata";

@Component({
    moduleId: module.id + '',
    selector: "sis-multilingual-textbox-settings",
    template: `
		<sis-ng-control-label-settings [languages]="languages" [currentLanguageId]="currentLanguageId"
									   [(titleModel)]="controlLabel">
		</sis-ng-control-label-settings>
        <sis-ng-hint-settings [(hint)]="hint" [languages]="languages" [currentLanguageId]="currentLanguageId">
        </sis-ng-hint-settings>
		<sis-ng-character-counter-settings [(settings)]="characterCountSettings">
		</sis-ng-character-counter-settings>
        <sis-ng-tooltip-settings [(settings)]="tooltipSettings" [languages]="languages" [currentLanguageId]="currentLanguageId">
        </sis-ng-tooltip-settings>
    `
})
@ControlMetadata({
    template: `<sis-multilingual-textbox-settings [state]="%{state}" [languages]="%{languages}"
                                                  [currentLanguageId]="%{currentLanguageId}"  
                                                  [controlLabel]="%{controlLabel}">
                </sis-multilingual-textbox-settings>`
})
export class MultilingualTextboxSettings extends BaseSettings {
    constructor(communicationService: CommunicationService) {
        super(communicationService);
    }
}
