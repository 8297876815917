/**
 * @Author: Perch Voskerchyan
 * @Date: 02.04.2018
 */

import {Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import './sis-color-picker.component.css';
import {ColorPickerPalette} from "./sis-color-picker.palette";

const COLOR_PICKER_CONTROL_VALUE_ACCESSOR = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => ColorPickerComponent),
	multi: true
};

@Component({
	moduleId: module.id,
	selector: 'sis-color-picker',
	templateUrl: 'sis-color-picker.component.html',
	providers: [COLOR_PICKER_CONTROL_VALUE_ACCESSOR]
})

export class ColorPickerComponent implements ControlValueAccessor {
	
	@Input()
	public id: string;
	
	public _color: string;
    public _palette: string;
    public _shade: string;

	/**
	 * Placeholders for callbacks. Used for implementing ControlValueAccessor interface
	 */
	private _onTouchedCallback: () => {};
	private _onChangeCallback: (_: any) => {};
	
	
	public palettes: Array<{name:string, shades: any[]}> = ColorPickerPalette;

	/**
	 *
	 * @param model
	 */
	writeValue(model: any) {
		if (model !== null && model !== undefined) {
			if (typeof model === 'string') {
				this._color = model;
				this._palette = null;
				this._shade = null;
			} else {
				this._color = model.color;
				this._palette = model.palette;
				this._shade = model.shade;
			}
			
		} else {
			this._color = null;
			this._palette = null;
			this._shade = null;
        }
	}
	
	/**
	 * ControlValueAccessor interface function.
	 */
	registerOnChange(fn: any) {
		this._onChangeCallback = fn;
	}
	
	/**
	 * ControlValueAccessor interface function.
	 */
	registerOnTouched(fn: any) {
		this._onTouchedCallback = fn;
	}
	
	onColorSelect(color: string, palette: string, shade: string) {
		this._color = color;
		this._palette = palette;
		this._shade = shade;
		this._onChangeCallback({color: this._color, palette: this._palette, shade: this._shade});
	}

	/**
	 *
	 * @param {number} decimalNumber color representation in decimal.
	 * @returns {string}
	 */
	private componentToHex(decimalNumber: number): string {
		const hex = decimalNumber.toString(16);
		return hex.length === 1 ? `0${hex}` : hex;
	}

	public trackByName (index: number, item: any): string {
		return item.name;
	}
}

