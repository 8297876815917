/**
 * @author Vahagn Lazyan.
 */
import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ControlValueAccessor} from '@angular/forms';

import {Language, MultilingualString} from '@synisys/idm-crosscutting-concepts-frontend';
import './sis-control-label-settings.component.css';

@Component({
             moduleId   : module.id + '',
             selector   : 'sis-ng-control-label-settings',
             templateUrl: 'sis-control-label-settings.component.html',
           })
export class SisControlLabelSettingsComponent implements OnInit, AfterViewInit {

  @Input()
  public languages: Language[];

  @Input()
  public currentLanguageId: number;

  public label: MultilingualString;

  @Output()
  public titleModelChange: EventEmitter<TitleJson> = new EventEmitter<TitleJson>();

  public get isReady(): boolean {
    return this._isReady;
  }

  @Input()
  public titleModel: TitleJson;

  @ViewChild('labelInput')
  private labelInput: ControlValueAccessor;

  private _isReady = false;

  public ngOnInit(): void {
    if (this.titleModel === undefined) {
      this.createEmptyLabel();
      this.titleModel = {
        title_src  : 'custom',
        custom_text: this.mapToObj(this.label.toMap())
      };
    } else if (this.titleModel.title_src === 'custom') {
      this.label = MultilingualString.newBuilder()
                                     .fromMap(this.objToMap(this.titleModel.custom_text))
                                     .build();
    } else {
      this.createEmptyLabel();
    }
    this._isReady = true;
  }

  public ngAfterViewInit(): void {
    this.labelInput
        .registerOnChange((label: MultilingualString) => {
          this.label = label;
          this.pushTitleModel(this.titleModel, label);
        });
  }

  private pushTitleModel(model: TitleJson, label: MultilingualString): void {
    let customTitleLength = 0;
    const labelMap = label.toMap();
    this.titleModel.title_src = undefined;
    this.titleModel.custom_text = this.mapToObj(labelMap);
    labelMap.forEach(value => customTitleLength += (value ? value.length : 0));
    if (customTitleLength > 0) {
      this.titleModel.title_src = 'custom';
    }
    this.titleModelChange.emit(model);
  }

  private createEmptyLabel(): void {
    this.label = MultilingualString.newBuilder()
                                   .withValueForLanguages(
                                     this.languages.map(language => language.getId()), '')
                                   .build();
  }

  private mapToObj(map: Map<number, string>): object {
    const obj = {};
    map.forEach((v, k) => {
      obj[k] = v;
    });
    return obj;
  }

  private objToMap(obj: object): Map<number, string> {
    const map = new Map();
    Object.keys(obj).forEach(k => {
      map.set(+k, obj[k]);
    });
    return map;
  }

}

export interface TitleJson {
  title_msg?: string;
  title_src?: string;
  custom_text?: object;
}

