import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import './sis-information-box.component.css';

@Component({
    moduleId: module.id,
    selector: 'sis-information-box',
    templateUrl: "sis-information-box.component.html",
})
export class InformationBoxComponent implements OnInit {

    @Input() id : string;
    @Input() iconId: string;
    @Input() title: string;
    @Input() message: string;
    @Input() width: number = 320;
    @Input() color: string = "light";
    @Input() position: string = "isRight";

    isVisible: boolean = false;

    private windowWidth: number;
    private windowHeight: number;

    @ViewChild('messageBox') messageBox: ElementRef;

    constructor() {
        this.calculateInformationBoxCoordinates();
        window.addEventListener("resize", this.calculateInformationBoxCoordinates);
    }

    ngOnInit(): void {

    }

    onMouseEntered(event: any): void {
        this.isVisible = true;
    }

    onMouseLeaved(): void {
        this.isVisible = false;
    }

    calculateInformationBoxCoordinates(): void {
        this.windowWidth = window.innerWidth;
        this.windowHeight = window.innerHeight;

        //SisInformationBoxComponent width and max-height
        //320 x 400

        //console.log(this.windowWidth, this.windowHeight);
    }
}
