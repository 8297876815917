import {Pipe, PipeTransform} from '@angular/core';


@Pipe({
    name: 'multiselect',
    pure: true
})
export class MultiselectTransformPipe implements PipeTransform {


    public constructor() {
    }

    transform(selectedValues: number[], nameField: string, valueField: string, data: any[]): string {
        if (selectedValues === null || selectedValues === undefined || selectedValues.length === 0 || data === null || data === undefined || data.length === 0) {
            return "";
        }

        return data
            .filter((dataItem: any) => {
                return selectedValues.indexOf(dataItem[valueField]) !== -1;
            })
            .map((value: any) => {
                return value[nameField];
            })
            .join(", ");
    }

}
