import {Component, EventEmitter, Input, OnChanges, OnInit, Optional, Output} from "@angular/core";
import {FontStyle} from "../shared/font-style";
import {Observable} from "rxjs/Observable";
import './sis-font-style-selector.component.css';
import {ControlsTranslationService} from "../shared/controls-translation.service";

@Component({
    moduleId: module.id,
    selector: "sis-font-style-selector",
    templateUrl: "sis-font-style-selector.component.html",
})
export class FontStyleSelectorComponent implements OnInit, OnChanges{
    @Input() id: string = '';
    @Input('value') font: FontStyle = null;
    @Output('valueChange') fontChange: EventEmitter<FontStyle> = new EventEmitter();

    @Input() fontNameList: string[] = ['Arial', 'Tahoma', 'Verdana', 'Times New Roman', 'Impact'];
    @Input() fontSizeList: number[] = [10, 12, 14, 16, 18, 20, 25, 30];

    private _fontColor: any;
    private _backgroundColor: any;

    public get fontColor (): any {
        return this._fontColor;
    }

    public set fontColor (value: any) {
        this._fontColor = value;
        this.font.color = this._fontColor.color;
        this.update();
    }

    public get backgroundColor (): any {
        return this._backgroundColor;
    }

    public set backgroundColor (value: any) {
        this._backgroundColor = value;
        this.font.backgroundColor = this._backgroundColor.color;
        this.update();
    }

    constructor (@Optional() private translationService: ControlsTranslationService) {

    }

    ngOnInit () {
        this.setupFont();
    }

    ngOnChanges () {
        this.setupFont();
    }

    private setupFont () {
        this.font = this.font || {
            fontName: 'Arial',
            fontSize: 12,
            isBold: false,
            isItalic: false,
            isUnderline: false,
            color: '#000000',
            backgroundColor: '#ffffff',
            align: 'left'
        };
    }

    update () {
        this.fontChange.emit(this.font);
    }

    public getMessage (key: string): Observable<string> {
        return this.translationService ? this.translationService.getMessage(key) : Observable.of(key);
    }

    public trackByIndex (index: number) {
        return index;
    }
}
