import {Observable} from 'rxjs/Observable';
import {DataSource} from './data-source';
import {of} from 'rxjs/observable/of';
import {isObservable} from '../virtual-scroller/virtual-for-of/virtual-for-of';


/** DataSource wrapper for a native array. */
export class ArrayDataSource<T> extends DataSource<T> {
	constructor(private _data: T[] | ReadonlyArray<T> | Observable<T[] | ReadonlyArray<T>>) {
		super();
	}

	connect(): Observable<T[] | ReadonlyArray<T>> {
		return isObservable(this._data) ? this._data : of(this._data);
	}

	disconnect() {
	}
}
