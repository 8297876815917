import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import './sis-date-range-picker.component.css';
@Component({
    moduleId: module.id,
    selector: 'sis-date-range-picker',
    templateUrl: "sis-date-range-picker.component.html",
})
export class DateRangePickerComponent implements OnInit, AfterViewInit {
    public dateRangePopupOpen: boolean = false;

    @Input()
    public id: string;

    @Input()
    public dateFrom: Date;

    @Output()
    public dateFromChange: EventEmitter<Date> = new EventEmitter();

    @Input()
    public dateTo: Date;

    @Output()
    public dateToChange: EventEmitter<Date> = new EventEmitter();

    @ViewChild('dateRangeContainer')
    private dateRangeContainer: ElementRef;

    @Input()
    public dateFilter: Function;

    public dateSelectorSecondDate: boolean = true;

    private markedDates: any = {};

    private needToUpdate: boolean = false;

    constructor (private cdRef: ChangeDetectorRef) {

    }

    public ngOnInit() {
        if (!this.dateTo) {
            this.dateTo = new Date();
            this.needToUpdate = true;
        }

        if (!this.dateFrom) {
            let dateFrom: Date = new Date(this.dateTo.getTime());
            dateFrom.setDate(1);
            this.dateFrom = dateFrom;
            this.needToUpdate = true;
        }
    }

    public ngAfterViewInit () {
        this.updateMarkedDates();
        if (this.needToUpdate) {
            setTimeout(() => {
                this.dateFromChange.emit(this.dateFrom);
                this.dateToChange.emit(this.dateTo);
                this.cdRef.detectChanges();
            },0);
        }
    }

    public toggleDateRangePicker() {
        this.dateRangePopupOpen = !this.dateRangePopupOpen;
    }

    public onSelect (event: any) {

        this.dateSelectorSecondDate = !this.dateSelectorSecondDate;

        if (!this.dateSelectorSecondDate) {
            this.clearAllMarkedDays();
            this.dateFrom = this.convertTimeToUTC(event);
            this.dateFromChange.emit(this.dateFrom);
            this.updateMarkedDates();
        } else {

            this.dateTo = this.convertTimeToUTC(event);
            this.dateToChange.emit(this.dateTo);

            if (this.dateTo.getTime() <= this.dateFrom.getTime()) {
                this.dateSelectorSecondDate = true;
                this.onSelect(event);
                return;
            }

            this.updateMarkedDates();
        }
    }

    private updateMarkedDates () {

        this.clearAllMarkedDays();

        if (this.dateFrom && this.dateTo && this.dateSelectorSecondDate) {
            let dates: Date[] = this.getDatesRange(this.dateFrom, this.dateTo);
            dates.forEach((date: Date) => {
                this.markDate(this.convertDateToDom(date), 'sis-date-range__mid');
            });
        }

        if (this.dateFrom) {
            this.markDate(this.convertDateToDom(this.dateFrom), 'sis-date-range__edge');
        }

        if (this.dateSelectorSecondDate && this.dateTo) {
            this.markDate(this.convertDateToDom(this.dateTo), 'sis-date-range__edge');
        }
    }

    private convertDateToDom (date: Date): string {
        return date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
    }

    private clearAllMarkedDays () {
        for (let k in this.markedDates) {
            this.markDate(k, 'sis-date-range__date--end-none');
        }
        this.markedDates = {};
    }

    private markDate (date: string, className: string) {

        let d = this.dateRangeContainer.nativeElement.querySelector("td[aria-label='" + date + "']");

        if (d) {
            if (this.markedDates[date]) {
                d.classList.remove(this.markedDates[date]);
            }
            d.classList.add(className);
        }

        this.markedDates[date] = className;
    }

    public calendarChanged() {
        this.updateMarkedDates();
    }

    private convertTimeToUTC(date: Date): Date {
        return date && new Date(Date.UTC(
            date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(),
            date.getMinutes(), date.getSeconds(), date.getMilliseconds()));
    }

    private getDatesRange(startDate: Date, stopDate: Date) {
        var dateArray = new Array();
        var currentDate = new Date(startDate.getTime());
        while (currentDate <= stopDate) {
            dateArray.push(new Date (currentDate));
            currentDate = this.addDays(currentDate,1);
        }
        return dateArray;
    }

    private addDays(date: Date, days: number) {
        date.setDate(date.getDate() + days);
        return date;
    };

    public drawDate (date: Date) {
        if (this.dateFilter) {
            return this.dateFilter(date);
        }
        return date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
    }
}
