/**
 * @author Vahagn Lazyan.
 */

import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';

import {Language, MultilingualString} from '@synisys/idm-crosscutting-concepts-frontend';
import {MultilingualComponent} from "../../multilingual-component/sis-multilingual.component";
import {ControlMetadata} from "@synisys/idm-dynamic-controls-metadata";
import './sis-hint-settings.component.css';

@Component({
    moduleId: module.id + '',
    selector: 'sis-ng-hint-settings',
    templateUrl: 'sis-hint-settings.component.html'
})
@ControlMetadata({
    template: `<sis-ng-hint-settings
                    [hint]="%{hint}"
                    [languages]="%{languages}"
                    [currentLanguageId]="%{currentLanguageId}">
               </sis-ng-hint-settings>`
})
export class SisHintSettingsComponent implements OnInit {

    @Input()
    public hint: MultilingualString;

    @Input()
    public languages: Language[];

    @Input()
    public currentLanguageId: number;

    @Output()
    public hintChange: EventEmitter<MultilingualString> = new EventEmitter<MultilingualString>();

    get isReady(): boolean {
        return this._isReady;
    }

    private _isReady = false;

    @ViewChild('hintInput')
    private hintInput: MultilingualComponent;


    public ngOnInit(): void {
        if (this.hint === undefined) {
            this.hint = MultilingualString.newBuilder()
                .withValueForLanguages(
                    this.languages.map(language => language.getId()), '')
                .build();
        }
        this._isReady = true;
    }

    public ngAfterViewInit(): void {
        this.hintInput.registerOnChange((hint: MultilingualString) => this.hintChange.emit(hint));
    }

}
