/**
 * @author Vahagn Lazyan.
 */
import {Input} from "@angular/core";
import {MultilingualString} from "@synisys/idm-crosscutting-concepts-frontend";
import {stringToMultilingual} from "./settings-utils";
import {CharacterCountSettings} from "./character-counter/character-count.settings";

export class SisControlWithSettingsBase {

    protected _hint: MultilingualString;

    protected _characterCountSettings: CharacterCountSettings;

    get hint(): MultilingualString {
        return this._hint;
    }

    @Input()
    set hint(multilingualString: MultilingualString) {
        if (multilingualString instanceof MultilingualString) {
            this._hint = multilingualString;
        } else if (typeof multilingualString == "string") {
            this._hint = stringToMultilingual(multilingualString);
        } else {
            this._hint = null;
        }
    }

    get characterCountSettings(): CharacterCountSettings {
        return this._characterCountSettings;
    }


    @Input()
    set characterCountSettings(settings: CharacterCountSettings) {
        if(settings instanceof CharacterCountSettings) {
            this._characterCountSettings = settings;
        } else if (typeof settings == "string") {
            this._characterCountSettings = CharacterCountSettings.fromJsonString(settings);
        } else {
            this._characterCountSettings = null;
        }
    }
}
