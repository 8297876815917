import {Component, EventEmitter, Input, OnInit, Optional, Output} from "@angular/core";
import {Observable} from "rxjs/Observable";
import {ControlsTranslationService} from "../shared/controls-translation.service";
import './sis-icon-upload.component.css';

@Component({
    moduleId: module.id,
    selector: "sis-icon-upload",
    templateUrl: "sis-icon-upload.component.html"
})
export class SisIconUpload implements OnInit {

    private readonly EXTENSION = 'PNG';

    @Input() dimension: number = 20;

    @Input() id: string;
    @Input() iconUrl: string;

    @Output() onIconUpload = new EventEmitter<File>();
    @Output() onIconDelete = new EventEmitter<any>();

    private _documentPresent: boolean;
    private _uploadedFile: File;
    private _validationMessage: string = "";
    private _imageUrl: string = "";

    constructor(@Optional() private translationService: ControlsTranslationService) {
    }

    ngOnInit(): void {
        if (this.iconUrl) {
            this._imageUrl = this.iconUrl;
            this._documentPresent = true;
        }
    }

    onFileChange(event: any) {
        if (event.target.files.length > 0) {
            this._uploadedFile = event.target.files[0];
            this._validationMessage = "";
            this.uploadFile();
        }
    }

    onDrop(event: any) {
        event.preventDefault();

        if (event.dataTransfer.items) {
            this._uploadedFile = event.dataTransfer.items[0].getAsFile();
        } else {
            this._uploadedFile = event.dataTransfer.files[0];
        }

        this._validationMessage = '';
        this.uploadFile();
    }

    onDragOver(event: any) {
        event.preventDefault();
    }

    onDragLeave(event: any) {
        event.preventDefault();
    }

    cancelFile() {
        this.clearFields();
    }

    private uploadFile() {
        this.validate().subscribe((_: any) => {
            this.compressAndUpload();

        }, err => {
            this.clearFields();
            this._validationMessage = err;
        });
    }

    private validate(): Observable<any> {
        if (!this._uploadedFile) {
            return this.translationService.getMessage('ng_controls.icon_upload.no_file')
                .switchMap(err => Observable.throw(err));
        }

        if (!this.isValidFileExtension()) {
            return this.translationService.getMessageWithPlaceholder(
                'ng_controls.icon_upload.extension_validation',
                [this.EXTENSION])
                .switchMap(err => Observable.throw(err));
        }

        return Observable.of(true);
    }

    private isValidFileExtension(): boolean {
        return this._uploadedFile.name.toLowerCase().endsWith(this.EXTENSION.toLowerCase());
    }

    private compressAndUpload() {
        this.compressFile().subscribe(file => {
            this.onIconUpload.emit(file);
        });
    }

    private compressFile(): Observable<File> {
        const reader = new FileReader();
        reader.readAsDataURL(this._uploadedFile);

        return Observable.create((observer: any) => {
            reader.onload = ev => {
                const img = new Image();
                img.src = (ev.target as any).result as string;
                this._documentPresent = true;

                img.onload = () => {
                    const elem = document.createElement('canvas');
                    elem.setAttribute('style', 'display: none;');
                    elem.width = this.dimension;
                    elem.height = this.dimension;

                    const ctx = <CanvasRenderingContext2D>elem.getContext('2d');
                    ctx.drawImage(img, 0, 0, this.dimension, this.dimension);

                    ctx.canvas.toBlob(
                        blob => {
                            this.extractImageSrc(blob);

                            observer.next(
                                new File([blob], this._uploadedFile.name, {
                                    type: 'image/png',
                                    lastModified: Date.now(),
                                }),
                            );
                        },
                        'image/png',
                        1,
                    );
                };
                reader.onerror = error => observer.error(error);
            };
        });
    }

    private extractImageSrc(blob: Blob): void {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = ev => {
            this._imageUrl = (ev.target as any).result as string;
        }
    }

    private clearFields() {
        this.onIconDelete.emit();
        this._documentPresent = false;
        this._uploadedFile = null;
        this._imageUrl = '';
    }

    get documentPresent(): boolean {
        return this._documentPresent;
    }

    get validationMessage(): string {
        return this._validationMessage;
    }

    get imageUrl(): any {
        return this._imageUrl;
    }

    public getMessage (key: string, placeholder?: string[]): Observable<string> {
        if (!placeholder) {
            return this.translationService ? this.translationService.getMessage(key) : Observable.of(key);
        }
        return this.translationService ? this.translationService.getMessageWithPlaceholder(key, placeholder) : Observable.of(key);
    }
}
