/**
 * @author Vahagn Lazyan.
 */

export class CharacterCountSettings {

    public static fromJsonString(json: string) {
        return new CharacterCountSettings(json == "" ? false: JSON.parse(json)['showCharacterCount']);
    }

    constructor(public showCharacterCount: boolean) {
    }

    public toJsonString(): string {
        return JSON.stringify({
                                  showCharacterCount: this.showCharacterCount,
                              });
    }
}
