import {Language, MultilingualString} from "@synisys/idm-crosscutting-concepts-frontend";
import {stringToMultilingual, multilingualToString} from "../settings-utils";

/**
 * @author Vahagn Lazyan.
 */
export class TooltipSettings {

    public static createEmptyFrom(languages: Language[]) {
        let languageIds: number[] = languages.map(language => language.getId());
        return new TooltipSettings(false,
            MultilingualString.newBuilder()
                .withValueForLanguages(languageIds, '')
                .build(),
            MultilingualString.newBuilder()
                .withValueForLanguages(languageIds, '')
                .build());
    }

    public static fromJsonString(json: string) {
        const obj: object = JSON.parse(json);
        return new TooltipSettings(obj['isWithTooltip'],
            stringToMultilingual(obj['caption']),
            stringToMultilingual(obj['content']));
    }

    constructor(public isWithTooltip: boolean,
                public caption?: MultilingualString | undefined,
                public content?: MultilingualString | undefined) {
    }

    public toJsonString(): string {
        return JSON.stringify({
            isWithTooltip: this.isWithTooltip,
            caption: multilingualToString(this.caption),
            content: multilingualToString(this.content)
        });
    }

}

