export const ColorPickerPalette = [
    {
        "name": "grey",
        "shades": [
            {
                "name": "1000",
                "color": "#000000"
            },
            {
                "name": "0",
                "color": "#ffffff"
            },
            {
                "name": "100",
                "color": "#f5f5f5"
            },
            {
                "name": "200",
                "color": "#eeeeee"
            },
            {
                "name": "300",
                "color": "#e0e0e0"
            },
            {
                "name": "400",
                "color": "#bdbdbd"
            },
            {
                "name": "500",
                "color": "#9e9e9e"
            },
            {
                "name": "700",
                "color": "#616161"
            }
        ]
    },
    {
        "name": "red",
        "shades": [
            {
                "name": "500",
                "color": "#f44336"
            },
            {
                "name": "50",
                "color": "#ffebee"
            },
            {
                "name": "100",
                "color": "#ffcdd2"
            },
            {
                "name": "200",
                "color": "#ef9a9a"
            },
            {
                "name": "300",
                "color": "#e57373"
            },
            {
                "name": "400",
                "color": "#ef5350"
            },
            {
                "name": "700",
                "color": "#d32f2f"
            },
            {
                "name": "900",
                "color": "#b71c1c"
            }
        ]
    },
    {
        "name": "purple",
        "shades": [
            {
                "name": "500",
                "color": "#9c27b0"
            },
            {
                "name": "50",
                "color": "#f3e5f5"
            },
            {
                "name": "100",
                "color": "#e1bee7"
            },
            {
                "name": "200",
                "color": "#ce93d8"
            },
            {
                "name": "300",
                "color": "#ba68c8"
            },
            {
                "name": "400",
                "color": "#ab47bc"
            },
            {
                "name": "700",
                "color": "#7b1fa2"
            },
            {
                "name": "900",
                "color": "#4a148c"
            }
        ]
    },
    {
        "name": "blue",
        "shades": [
            {
                "name": "500",
                "color": "#2196f3"
            },
            {
                "name": "50",
                "color": "#e3f2fd"
            },
            {
                "name": "100",
                "color": "#bbdefb"
            },
            {
                "name": "200",
                "color": "#90caf9"
            },
            {
                "name": "300",
                "color": "#64b5f6"
            },
            {
                "name": "400",
                "color": "#42a5f5"
            },
            {
                "name": "700",
                "color": "#1976d2"
            },
            {
                "name": "900",
                "color": "#0d47a1"
            }
        ]
    },
    {
        "name": "cyan",
        "shades": [
            {
                "name": "500",
                "color": "#00bcd4"
            },
            {
                "name": "50",
                "color": "#e0f7fa"
            },
            {
                "name": "100",
                "color": "#b2ebf2"
            },
            {
                "name": "200",
                "color": "#80deea"
            },
            {
                "name": "300",
                "color": "#4dd0e1"
            },
            {
                "name": "400",
                "color": "#26c6da"
            },
            {
                "name": "700",
                "color": "#0097a7"
            },
            {
                "name": "900",
                "color": "#006064"
            }
        ]
    },
    {
        "name": "green",
        "shades": [
            {
                "name": "500",
                "color": "#4caf50"
            },
            {
                "name": "50",
                "color": "#e8f5e9"
            },
            {
                "name": "100",
                "color": "#c8e6c9"
            },
            {
                "name": "200",
                "color": "#a5d6a7"
            },
            {
                "name": "300",
                "color": "#81c784"
            },
            {
                "name": "400",
                "color": "#66bb6a"
            },
            {
                "name": "700",
                "color": "#388e3c"
            },
            {
                "name": "900",
                "color": "#1b5e20"
            }
        ]
    },
    {
        "name": "yellow",
        "shades": [
            {
                "name": "500",
                "color": "#ffeb3b"
            },
            {
                "name": "50",
                "color": "#fffde7"
            },
            {
                "name": "100",
                "color": "#fff9c4"
            },
            {
                "name": "200",
                "color": "#fff59d"
            },
            {
                "name": "300",
                "color": "#fff176"
            },
            {
                "name": "400",
                "color": "#ffee58"
            },
            {
                "name": "700",
                "color": "#fbc02d"
            },
            {
                "name": "900",
                "color": "#f57f17"
            }
        ]
    },
    {
        "name": "deep-orange",
        "shades": [
            {
                "name": "500",
                "color": "#ff5722"
            },
            {
                "name": "50",
                "color": "#fbe9e7"
            },
            {
                "name": "100",
                "color": "#ffccbc"
            },
            {
                "name": "200",
                "color": "#ffab91"
            },
            {
                "name": "300",
                "color": "#ff8a65"
            },
            {
                "name": "400",
                "color": "#ff7043"
            },
            {
                "name": "700",
                "color": "#e64a19"
            },
            {
                "name": "900",
                "color": "#bf360c"
            }
        ]
    },
    {
        "name": "brown",
        "shades": [
            {
                "name": "600",
                "color": "#6d4c41"
            },
            {
                "name": "50",
                "color": "#efebe9"
            },
            {
                "name": "100",
                "color": "#d7ccc8"
            },
            {
                "name": "200",
                "color": "#bcaaa4"
            },
            {
                "name": "300",
                "color": "#a1887f"
            },
            {
                "name": "400",
                "color": "#8d6e63"
            },
            {
                "name": "700",
                "color": "#5d4037"
            },
            {
                "name": "900",
                "color": "#3e2723"
            }
        ]
    }
];
