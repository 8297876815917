/**
 * @author Vahagn Lazyan.
 */
import {Component} from "@angular/core";

import {BaseSettings} from "./sis-base-settings";
import {CommunicationService, ControlMetadata} from "@synisys/idm-dynamic-controls-metadata";

@Component({
    moduleId: module.id + '',
    selector: "sis-search-combo-box-settings",
    template: `
		<sis-ng-control-label-settings [languages]="languages" [currentLanguageId]="currentLanguageId"
									   [(titleModel)]="controlLabel">
		</sis-ng-control-label-settings>
        <sis-ng-hint-settings [(hint)]="hint" [languages]="languages" [currentLanguageId]="currentLanguageId">
        </sis-ng-hint-settings>
        <sis-ng-tooltip-settings [(settings)]="tooltipSettings" [languages]="languages" [currentLanguageId]="currentLanguageId">
        </sis-ng-tooltip-settings>
    `
})
@ControlMetadata({
    template: `<sis-search-combo-box-settings [state]="%{state}" [languages]="%{languages}" 
                                              [currentLanguageId]="%{currentLanguageId}" 
                                              [controlLabel]="%{controlLabel}">
                </sis-search-combo-box-settings>`
})
export class SearchComboBoxSettings extends BaseSettings {
    constructor(communicationService: CommunicationService) {
        super(communicationService);
    }
}
