/**
 * Created by mariam.melkonyan on 5/19/18.
 */

import {
    AfterContentInit,
    ContentChildren,
    ElementRef, EventEmitter,
    Input,
    OnInit,
    Optional, Output,
    QueryList,
    ViewChild
} from "@angular/core";
import {SelectItemDirective} from "../shared/sis-select-item.directive";
import {MatMenuTrigger} from "@angular/material";
import {SisControlWithSettingsBase} from "../control-settings/sis-control-with-settings-base";
import {SubscriptionManager} from "../shared/subscription-manager.decorator";
import {Observable} from "rxjs/Observable";
import {ControlsTranslationService} from "../shared/controls-translation.service";

/**
 * SearchComboBoxComponent is comboBox from its children.
 * Gets as input:
 *      id - The unique id for control. Mainly is used for automated testing.
 *      placeHolder - The place holder of comboBox.
 *      value - The value which transfers selected item.
 */
@SubscriptionManager()
export abstract class ComboBoxComponent extends SisControlWithSettingsBase implements OnInit, AfterContentInit {
    public $sm: SubscriptionManager;

    /**
     * The combobox items data
     * @type {Array}
     */
    public processedDataItems: Array<any> = [];
    public processedGroupItems: Array<any> = [];

    /**
     * Combobox is rendered considering these items. These values are changed on user input.
     */
    public filteredItems: Array<any>;
    public filteredGroups: Array<any>;

    @Input()
    public isGrouped: boolean = false;

    @Input()
    public id: string;

    @Input()
    public placeHolder: string;

    @Input()
    public recordDeletedPlaceHolder: string = "";

    /**
     * The current language. This value is provided when the control is created.
     */
    @Input()
    public currentLanguageId: number;

    @Input()
    placeHolderDefaultValue: string = "Please Select";

    @Input()
    placeHolderDefaultValueKey: string = null;

    @Output()
    searchValueChange: EventEmitter<any> = new EventEmitter<any>();

    /**
     * @deprecated
     * will be removed, please use {@see isDisabled}
     */
    @Input()
    public isEnabled: boolean = true;

    @Input()
    public hasReset: boolean = true;

    @Input()
    public width: number = 340;

    protected _isDisabled: boolean = false;

    @Input()
    public set isDisabled(isDisabled: any) {
        this._isDisabled = isDisabled === '' ? false : isDisabled;
    }

    public get isDisabled() {
        return this._isDisabled;
    }

    /**
     * ComboBox items.
     * @type {QueryList<SelectItemDirective>}
     */
    @ContentChildren(SelectItemDirective)
    private comboBoxItems: QueryList<SelectItemDirective>;

    @ViewChild('input')
    protected input: ElementRef;

    @ViewChild('form')
    protected form: ElementRef;

    @ViewChild(MatMenuTrigger)
    protected menuTrigger: MatMenuTrigger;

    private itemsChanged: boolean = false;

    constructor(@Optional() protected translationService: ControlsTranslationService) {
        super();
    }

    ngOnInit() {
        if (this.placeHolderDefaultValueKey) {
            this.getMessage(this.placeHolderDefaultValueKey).subscribe((value: string) => {
                this.placeHolderDefaultValue = value;
            });
        }
    }

    /**
     * Sets content children parents,
     * creates processed data items and creates comboBox with this items.
     */
    ngAfterContentInit() {

        this.comboBoxItems.forEach((comboBoxItem: SelectItemDirective) => {
            comboBoxItem.parent = this;
            this.processDataItems(comboBoxItem);
        });
        this.processGroupItems();
        this.changeDisplayValues();
        this.filteredItems = this.processedDataItems;
        this.filteredGroups = this.processedGroupItems;
        this.$sm = this.comboBoxItems.changes.subscribe((changes: QueryList<SelectItemDirective>): void => {
            this.comboBoxItems = changes;
            this.onItemsChanged();
        });
    }

    /**
     * On comboBox items change recreates processed data items
     */
    protected onItemsChanged() {
        this.processedDataItems = [];
        this.comboBoxItems.forEach((comboBoxItem: SelectItemDirective) => {
            comboBoxItem.parent = this;
            this.processDataItems(comboBoxItem);
        });
        this.processGroupItems();
        this.changeDisplayValues();
        this.filteredItems = this.processedDataItems;
        this.filteredGroups = this.processedGroupItems;
        this.itemsChanged = true;
    }

    /**
     * Processes data items from child component data and creates json from it.
     * @param {SelectItemDirective} comboBoxItem - The child component.
     * @private
     */
    private processDataItems(comboBoxItem: SelectItemDirective): void {
        let item: any;
        if (comboBoxItem.data) {
            comboBoxItem.data.forEach((dataItem: any) => {
                item = {
                    value: comboBoxItem.valueField ? dataItem[comboBoxItem.valueField] : dataItem,
                    name: dataItem[comboBoxItem.nameField],
                    group: comboBoxItem.groupField ? dataItem[comboBoxItem.groupField] : "",
                };
                if (dataItem.isEnabled === false) {
                    item.isEnabled = dataItem.isEnabled;
                }
                this.processedDataItems.push(item);
            });
        } else {
            item = {
                value: comboBoxItem.value,
                name: comboBoxItem.name,
                group: comboBoxItem.group ? comboBoxItem.group : "",
            };
            if (comboBoxItem.isEnabled === false) {
                item.isEnabled = comboBoxItem.isEnabled;
            }
            this.processedDataItems.push(item);
        }
    }

    private processGroupItems(): void {
        let processedGroupItems: Array<any> = [];
        for (let item of this.processedDataItems) {
            let group: any = this.getGroupByName(processedGroupItems, item.group) || {name: item.group, items: []};
            group.items.push(item);
            group.items.length === 1 && (processedGroupItems.push(group));
        }
        this.processedGroupItems = processedGroupItems;
    }

    private getGroupByName(groups: Array<any>, groupName: string): any {
        for (let group of groups) {
            if (group.name === groupName) {
                return group;
            }
        }
        return null;
    }

    private filterItems(name: string) {
        return this.processedDataItems.filter((item: any) => {
            return item.name && item.name.toLowerCase().indexOf(name.toLowerCase()) !== -1;
        });
    }

    private filterGroups(name: string) {
        return this.processedGroupItems.map((group: any) => {
            return {
                name: group.name,
                items: group.items.filter((item: any) => {
                    return item.name && item.name.toLowerCase().indexOf(name.toString().toLowerCase()) !== -1;
                })
            }
        }).filter((group) => {
            return group.items.length > 0;
        });
    }

    private filter(event: any) {
        this.filteredGroups = this.filterGroups(event.target.value);
        this.filteredItems = this.filterItems(event.target.value);
    }

    public onFormClicked() {
        this.width = this.form.nativeElement.offsetWidth;
        this.input.nativeElement.focus();
    }

    public onInputChanged(event: any) {
        this.filter(event);
        this.searchValueChange.emit(event.target.value);

        if (event.target.value !== "" && event.keyCode === 13) {
            this.onEnterClicked();
            this.menuTrigger.closeMenu();
            this.filteredItems = this.processedDataItems;
            this.filteredGroups = this.processedGroupItems;
        } else if (!this.menuTrigger.menuOpen && event.target.value !== "") {
            this.width = this.form.nativeElement.offsetWidth;
            this.menuTrigger.openMenu();
        }
    }

    public resetItems() {
        // for resetting filter result after menu closed
        setTimeout(() => {
            this.filteredItems = this.processedDataItems;
            this.filteredGroups = this.processedGroupItems;
        }, 200);
    }

    public getPlaceHolder () {
        return (this.itemsChanged ? this.recordDeletedPlaceHolder : this.placeHolder) || this.placeHolder || this.placeHolderDefaultValue;
    }

    public trackByName (index: number, item: any): number {
        return item.name;
    }

    public trackByValue (index: number, item: any): number {
        return item ? item.value : null;
    }

    protected abstract changeDisplayValues(): void;

    protected abstract onEnterClicked(): void;

    public getMessage (key: string): Observable<string> {
        return this.translationService ? this.translationService.getMessage(key) : Observable.of(key);
    }
}
