/**
 * @author Vahagn Lazyan.
 */

import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from "@angular/core";
import {ControlValueAccessor} from "@angular/forms";

import {CharacterCountSettings} from "./character-count.settings";
import './sis-character-counter-settings.component.css';

@Component({
               moduleId   : module.id,
               selector   : 'sis-ng-character-counter-settings',
               templateUrl: 'sis-character-counter-settings.component.html'
           })
export class SisCharacterCounterSettingsComponent implements OnInit, AfterViewInit {

    @Input()
    public settings: CharacterCountSettings;

    @ViewChild('showCharacterCount')
    private showCharacterCountCheckbox: ControlValueAccessor;

    @Output()
    private settingsChange: EventEmitter<CharacterCountSettings> = new EventEmitter<CharacterCountSettings>();

    public ngOnInit(): void {
        if (this.settings === undefined) {
            this.settings = new CharacterCountSettings(false);
        }
    }

    public ngAfterViewInit(): void {
        this.showCharacterCountCheckbox.registerOnChange((value: boolean) => {
            this.settings.showCharacterCount = value;
            this.settingsChange.emit(this.settings);
        });
    }

}
