import {Pipe, PipeTransform} from '@angular/core';


@Pipe({
    name: 'searchable',
    pure: true
})
export class SearchableTransformPipe implements PipeTransform {

    public constructor() {
    }

    transform(selectedValue: number, nameField: string, valueField: string, data: any[]): string {
        if (selectedValue === null || selectedValue === undefined || data === null || data === undefined || data.length === 0) {
            return "";
        }

        const selectedItem: any = data
            .find((dataItem: any) => {
                return (selectedValue === dataItem[valueField]);
            });

        return selectedItem ? selectedItem[nameField] : "";
    }

}
