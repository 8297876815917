import {Component, EventEmitter, Input, OnInit, Optional, Output} from "@angular/core";
import {Observable} from "rxjs/Observable";
import {ControlsTranslationService} from "../shared/controls-translation.service";
import "./sis-image-select.component.css";
import {SubscriptionManager} from "../shared/subscription-manager.decorator";

@Component({
    moduleId: module.id,
    selector: "sis-image-select",
    templateUrl: "sis-image-select.component.html"
})
@SubscriptionManager()
export class ImageSelectComponent implements OnInit {

    public $sm: SubscriptionManager;

    @Input() id: string;
    @Input() imageUrls: string[] = [];
    @Input() isViewMode: boolean = false;
    @Input() selectedUrl: string;
    @Input() showBy: number = 10;
    @Output() onSelectionChange: EventEmitter<string> = new EventEmitter<string>();

    public isReady: boolean = false;
    public moreAnimating = false;

    private loadedImgCount: number = 0;
    private loadedVisibleImgCount: number = 0;
    private visibleImgCount: number;
    private firstVisibleImgCount: number;

    ngOnInit(): void {
        if (this.imageUrls) {
            this.firstVisibleImgCount = this.showBy > this.imageUrls.length ? this.imageUrls.length: this.showBy;
            this.visibleImgCount = this.firstVisibleImgCount;
            this.imageUrls.splice(0, 0, null);
            this.isReady = true;
        }
    }

    constructor (@Optional() private translationService: ControlsTranslationService) {
    }

    public loadMore(): void {
        this.visibleImgCount += this.showBy;
        this.moreAnimating = true;
        setTimeout(() => {
            this.moreAnimating = false;
        }, 0);
    }

    public imgLoaded(i: number) {
        if (i <= this.showBy) this.loadedVisibleImgCount++;
        this.loadedImgCount++;
    }

    public setSelectedUrl(url: string) {
        this.selectedUrl = url;
        this. onSelectionChange.emit(url);
    }

    public areAllImgVisible(): boolean {
        return this.visibleImgCount >= this.imageUrls.length - 1;
    }

    public areVisibleImgLoaded(): boolean {
        return this.loadedVisibleImgCount >= this.firstVisibleImgCount;
    }

    public isImgHidden(i: number): boolean {
        return i > this.visibleImgCount;
    }

    public getMessage (key: string): Observable<string> {
        return this.translationService ? this.translationService.getMessage(key) : Observable.of(key);
    }
}
