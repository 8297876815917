/**
 * Created by mariam.melkonyan on 4/20/18.
 */
import { NativeDateAdapter } from '@angular/material';
import * as moment from 'moment';
import {LocaleInfo} from "@synisys/idm-locale-service-client-js";

require('moment-jdateformatparser');

/** Adapts the native JS Date for use with cdk-based components that work with dates. */
export class CustomDateAdapter extends NativeDateAdapter {

    public formatting: string;
    public formattingJDF: string;
    public _localeInfo: LocaleInfo;

    public get localeInfo () {
        return this._localeInfo;
    }

    public set localeInfo(localeInfo: LocaleInfo) {
        this._localeInfo = localeInfo;
        this.formattingJDF = localeInfo && localeInfo.datePattern;
        moment.locale(this.getLocale());
    }

    parse(value: any): Date | null {
        if ((typeof value === 'string') && (value.indexOf('/') > -1)) {
            const str = value.split('/');

            const year = Number(str[2]);
            const month = Number(str[1]) - 1;
            const date = Number(str[0]);

            return new Date(year, month, date);
        }
        const timestamp = typeof value === 'number' ? value : Date.parse(value);
        return isNaN(timestamp) ? null : new Date(timestamp);
    }

    getMonthNames(style: 'long' | 'short' | 'narrow'): string[] {
        let momentLocaleData = moment.localeData(moment.locale());
        return style === 'short' ? momentLocaleData.monthsShort() : momentLocaleData.months();
    }

    getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
        let momentLocaleData = moment.localeData(moment.locale());
        if (style == 'long') {
            return momentLocaleData.weekdays();
        }
        if (style == 'short') {
            return momentLocaleData.weekdaysShort();
        }
        return momentLocaleData.weekdaysMin();
    }

    // retirar quando for feito o merge da data por mmalerba
    format(date: Date, displayFormat: any): string {
        if (displayFormat && displayFormat.year && displayFormat.month && !displayFormat.day) {
            return this.ucwords(moment(date).format('MMMM Y') || '');
        }
        if (this.formattingJDF) {
            return this.ucwords(moment(date)['formatWithJDF'](this.formattingJDF) || '');
        }
        return this.ucwords(moment(date).format(this.formatting || 'DD/MM/YYYY') || '');
    }

    ucwords( str: string ) {
        return str.replace(/^(.)|\s(.)/g, function ( $1 ) { return $1.toLocaleUpperCase(); } );
    }

    private getLocale(): string {
        let locales: string[] = (moment && moment.locales()) || [];
        let locale: string = (this._localeInfo && this._localeInfo.locale) || 'en';
        let country: string = ((this._localeInfo && this._localeInfo.country) || 'us').toLowerCase();
        if (locales.indexOf(locale + '-' + country) !== -1) {
            return locale + '-' + country;
        } else if (locales.indexOf(locale + '-' + country.toUpperCase()) !== -1) {
            return locale + '-' + country.toUpperCase();
        }

        return locale;
    }

}
