/**
 * @author Vahagn Lazyan.
 */
import {Input} from '@angular/core';

import {Language, MultilingualString} from "@synisys/idm-crosscutting-concepts-frontend";

import {CommunicationData, CommunicationKey, CommunicationService} from '@synisys/idm-dynamic-controls-metadata';

import {TooltipSettings} from "./tooltip/tooltip-settings.model";
import {multilingualToString, stringToMultilingual} from "./settings-utils";
import {CharacterCountSettings} from "./character-counter/character-count.settings";
import {TitleJson} from "./control-label/sis-control-label-settings.component";

export abstract class BaseSettings {

    @Input()
    public languages: Language[];

    @Input()
    public currentLanguageId: number;

    protected _controlLabel: TitleJson;

    protected _characterCountSettings: CharacterCountSettings;

    protected _tooltipSettings: TooltipSettings;

    protected _hint: MultilingualString;

    public get characterCountSettings(): CharacterCountSettings {
        return this._characterCountSettings;
    }

    public get tooltipSettings(): TooltipSettings {
        return this._tooltipSettings;
    }

    public get hint(): MultilingualString {
        return this._hint;
    }

    public set characterCountSettings(settings: CharacterCountSettings) {
        this._characterCountSettings = settings;
        this._communicationService.parentSubject.next(new CommunicationData(CommunicationKey.SETTINGS_CHANGED, {
            state: {
                characterCountSettings: this._characterCountSettings.toJsonString()
            }
        }));
    }

    public set tooltipSettings(settings: TooltipSettings) {
        this._tooltipSettings = settings;
        this._communicationService.parentSubject.next(new CommunicationData(CommunicationKey.SETTINGS_CHANGED, {
            state: {
                tooltipSettings: this._tooltipSettings.toJsonString()
            }
        }));
    }

    public set hint(hint: MultilingualString) {
        this._hint = hint;
        this._communicationService.parentSubject.next(new CommunicationData(CommunicationKey.SETTINGS_CHANGED, {
            state: {
                hint: multilingualToString(hint)
            }
        }));
    }

    protected constructor(protected _communicationService: CommunicationService) {
    }

    @Input()
    public set state(value: object) {
        const characterCountSettings = value["characterCountSettings"];
        const tooltipSettings = value["tooltipSettings"];
        const hint = value["hint"];

        if (characterCountSettings) {
            this._characterCountSettings = CharacterCountSettings.fromJsonString(characterCountSettings)
        }
        if (tooltipSettings) {
            this._tooltipSettings = TooltipSettings.fromJsonString(tooltipSettings);
        }
        if (hint) {
            this._hint = stringToMultilingual(hint);
        }
    }

    public get controlLabel(): TitleJson {
        return this._controlLabel;
    }

    @Input()
    public set controlLabel(controlLabel: TitleJson) {
        this._controlLabel = controlLabel;
        this._communicationService.parentSubject.next(new CommunicationData(CommunicationKey.SETTINGS_CHANGED, {
            controlLabel: controlLabel
        }));
    }

}
