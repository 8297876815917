import {ComboboxGroup, ComboboxOption, ComboboxOptions} from "../reporting-combo-box/shared/index";

/**
 * Initialize a subset of options from a number of identities and a collection of available options.
 * @param {Array<number>} identities - The identities to match the options to be chosen.
 * @param {ComboboxOptions<ComboboxOption<any, string>>} allOptions - All available options to choose from.
 * @returns {ComboboxOptions<ComboboxOption<any, string>>} A subset of options matching the identities.
 */
export function initComboOptions(identities: Array<number>, allOptions: ComboboxOptions<ComboboxOption<any, string>>): ComboboxOptions<ComboboxOption<any, string>> {
    const options: ComboboxOptions<ComboboxOption<any, string>> = [];

    identities.forEach((id: number): void => {
        let foundOption: ComboboxOption<any, string> = allOptions.find((option: ComboboxOption<any, string>) => {
            return option.value.id === id;
        });

        if (foundOption) {
            options.push(foundOption);
        }
    });

    return options;
}

export function createListItem(measure: any): any {
    const listItem = {
        id: measure.value.id,
        name: measure.name,
        order: measure.value.sortId,
        color: measure.color
    };

    return listItem;
}

export function initComboBoxOptions(data: ComboboxOptions<ComboboxGroup<ComboboxOptions<ComboboxOption<any, any>>, any>>) {
    return data.reduce((options: any[], group: ComboboxGroup<ComboboxOptions<ComboboxOption<any, any>>, any>) => {
        return options.concat(group.items);
    }, []);
}

export function filterGroupedData(groupedData: any[], currentItemId: number, selectedItems: any[]): any[] {
    if (currentItemId) {
        selectedItems = selectedItems.filter((item: any) => {
            return item !== currentItemId;
        })
    }
    return groupedData.map((group: any) => {
        return {
            name: group.name,
            items: group.items.filter((item: any) => {
                return selectedItems.indexOf(item.value.id) === -1;
            })
        }
    }).filter((group: any) => {
        return group.items.length > 0;
    })
}
