/**
 * Created by Mariam.Melkonyan on 6/20/2016.
 */
import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	forwardRef, Inject,
	Input,
	OnChanges,
	OnDestroy,
	OnInit, Renderer2,
	SimpleChanges, ViewChild
} from "@angular/core";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {ControlMetadata} from "@synisys/idm-dynamic-controls-metadata";
import {DatePickerSettings} from "../control-settings/sis-date-picker.settings";
import {SisControlWithSettingsBase} from "../control-settings/sis-control-with-settings-base";

const DATE_INPUT_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DatePicker),
    multi: true
};

/**
 * @deprecated use Angular Material DatePicker
 */
import "./sis-date-picker.component.css";
import {Language} from "@synisys/idm-crosscutting-concepts-frontend";
import {DateAdapter, MatDatepicker} from "@angular/material";
import {LocaleInfo} from "@synisys/idm-locale-service-client-js";
import {Observable} from "rxjs/Observable";
import {Subscription} from "rxjs/Subscription";
import {DOCUMENT} from "@angular/common";

@Component({
    moduleId: module.id,
    selector: "sis-date-picker",
    templateUrl: "sis-date-picker.component.html",
    providers: [DATE_INPUT_VALUE_ACCESSOR]
})
@ControlMetadata({
    groups: [
        {
            identity: "Controls",
            name: "Controls",
        },
    ],
    template: `%{#isreadonly}
                    <ng-container *ngIf="!%{isreadonly}">
                        <sis-date-picker [id]="'%{id}'" [(ngModel)]="%{field}" [getCurrentLocale]="%{getCurrentLocale}" [getPlaceholder]="%{getPlaceholder}" [currentLanguageId]="currentLanguageId" [hint]="%{hint}" [disabled]="%{disabled}"></sis-date-picker>
                    </ng-container>
                    <ng-container *ngIf="%{isreadonly}">
                        <label *ngIf="!%{sysLocalDate} && %{field}" [id]="'%{id}'">{{%{field} | date:'dd MMM yyyy'}}</label>
                        <label *ngIf="%{sysLocalDate} && %{field}" [id]="'%{id}'">{{%{sysLocalDate}(%{field}) | async}}</label>
                        <sis-custom-label *ngIf="!%{field}" [key]="'reporting.controls.chart-designer.placeholder.noData'"></sis-custom-label>
                    </ng-container>
               %{/isreadonly}
               %{^isreadonly}
                    <sis-date-picker [id]="'%{id}'" [getCurrentLocale]="%{getCurrentLocale}" [(ngModel)]="%{field}" [getPlaceholder]="%{getPlaceholder}" [currentLanguageId]="currentLanguageId" [hint]="%{hint}" [disabled]="%{disabled}"></sis-date-picker>
               %{/isreadonly}`,
    iconInfo: "fa-calendar",
    name: "Date picker",
    settings: {
        main: DatePickerSettings
    },
    isFieldBound: true,
    defaultActions: {
        'getCurrentLocale': 'getCurrentLocale',
        'getPlaceholder': 'getChooseDatePlaceholder',
        'sysLocalDate': 'sysLocalDate'
    }
})

/**
 * Implements ControlValueAccessor to work with ngModel.
 */
export class DatePicker extends SisControlWithSettingsBase implements OnInit, OnChanges, OnDestroy, ControlValueAccessor {

    @Input() id: string;
    @Input() disabled: boolean = false;
    @Input() inputReadonly: boolean = true;
    @Input() clearButton: boolean = true;
    @Input() placeholder: string = null;
    @Input() getPlaceholder: Observable<string> = null;
    @Input() getPlaceholder$: Subscription;
    @Input() languages: Language[] = null;
    @Input() formatting: string = null;
    @Input() getCurrentLocale: Observable<LocaleInfo> = null;
    @Input() getCurrentLocale$: Subscription;
    @Input() localeInfo: LocaleInfo = null;
    @Input() min: Date = null;
    @Input() max: Date = null;
	@Input() datesForMark: string[];

    public placeHolderDefaultValue: string = 'dd/mm/yyyy';

    /**
     * The current language. This value is provided when the control is created.
     */
    @Input()
    public languageCode: string = null;

    /**
     * Force detectChanges when pass new date
     */
    @Input() forceDetectChangesOnWriteDate: boolean = false;

    /**
     * Not used, left for legacy purposes.
     */
    @Input()
    public currentLanguageId: string = null;

	@ViewChild('picker')
	private dateRangeContainer: MatDatepicker<any>;

	public _date: any = null;

    //Placeholders for the callbacks
    private _onTouchedCallback: () => {};

    private _onChangeCallback: (_: any) => {};


	constructor(private elementRef: ElementRef, private adapter: DateAdapter<any>, private cdRef: ChangeDetectorRef,
	            @Inject(DOCUMENT) document: any,  private renderer: Renderer2) {
		super();
	}

    ngOnInit() {
        this.adapter['languageCode'] = this.languageCode;
        this.adapter['formatting'] = this.formatting;
        this.adapter['localeInfo'] = this.localeInfo;
        if (this.getCurrentLocale) {
            this.getCurrentLocale$ = this.getCurrentLocale.subscribe((localeInfo: LocaleInfo) => {
                this.adapter['localeInfo'] = localeInfo;
            });
        }
        if (this.getPlaceholder) {
            this.getPlaceholder$ = this.getPlaceholder.subscribe((placeholder: string) => {
                this.placeholder = placeholder;
            })
        }
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (changes['localeInfo']) {
            this.adapter['localeInfo'] = this.localeInfo;
            this.adapter['languageCode'] = this.localeInfo.locale;
            this.adapter['formatting'] = this.localeInfo.datePattern;

            this.resetDate();
        }

    }

    ngOnDestroy() {
        this.getCurrentLocale$ && this.getCurrentLocale$.unsubscribe && !this.getCurrentLocale$.closed && this.getCurrentLocale$.unsubscribe();
        this.getPlaceholder$ && this.getPlaceholder$.unsubscribe && !this.getPlaceholder$.closed && this.getPlaceholder$.unsubscribe();
    }

    get date() {
        return this._date;
    }

    set date(date) {
        this._date = date;
        this._onChangeCallback(this.convertTimeToUTC(date));
    }

    get seleniumTestClass() {
        return `${this.id}-datePicker-calendar`
    }

    //From ControlValueAccessor interface
    writeValue(date: any) {
        this._date = date;
        if(this.forceDetectChangesOnWriteDate){
            this.cdRef.detectChanges();
        }
    }

    //From ControlValueAccessor interface
    registerOnChange(callbackFunction: any) {
        this._onChangeCallback = callbackFunction;
    }

    //From ControlValueAccessor interface
    registerOnTouched(callbackFunction: any) {
        this._onTouchedCallback = callbackFunction;
    }

    private convertTimeToUTC(date: any): Date {
        date = (date && date.toDate && date.toDate()) || date;
        return date && new Date(Date.UTC(
            date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(),
            date.getMinutes(), date.getSeconds(), date.getMilliseconds()));
    }

    private resetDate() {
        const savedDate = this._date;

        this._date = null;
        this.cdRef.detectChanges();

		this._date = savedDate;
	}

	openCalendar() {
		if (this.datesForMark && this.datesForMark.length > 0){
			setTimeout(() => {

				let element = document.getElementById(this.dateRangeContainer.id) ;
				this.renderer.listen(element, 'click', () => {
					this.markDate(this.datesForMark, "ng-mat-calendar-body-cell--selected", element);
				});
				this.markDate(this.datesForMark, "ng-mat-calendar-body-cell--selected", element);
			}, 10);
		}
	}

	private markDate(dates: string[], className: string, element: HTMLElement) {

		dates.forEach(value => {
			let d = element.querySelector("td[aria-label='" + value + "']");

			if (d) {
				d.classList.add(className);
			}
		});

	}
}

