import {Component, Input, OnInit, Optional} from "@angular/core";
import {Observable} from "rxjs/Observable";
import {ControlsTranslationService} from "../shared/controls-translation.service";
import './sis-custom-label.component.css';

@Component({
    moduleId: module.id,
    selector: "sis-custom-label",
    templateUrl: "sis-custom-label.component.html"
})
export class CustomLabelComponent {

    @Input() key: string;

    constructor(@Optional() private translationService: ControlsTranslationService) {
    }

    public getMessage (key: string): Observable<string> {
        return this.translationService ? this.translationService.getMessage(key) : Observable.of('No Data');
    }
}
