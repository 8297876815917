/**
 * Created by Anushavan.Hovsepyan on 10/27/2016.
 */
import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Optional,
    Output,
    ViewChild
} from "@angular/core";
import {ControlMetadata} from "@synisys/idm-dynamic-controls-metadata";
import {ComboBoxComponent} from "./sis-combo-box.component";
import {MatMenuTrigger} from "@angular/material";
import {SearchComboBoxSettings} from "../control-settings/sis-search-combo-box.settings";

import "./sis-search-combo-box.component.css";
import {ControlsTranslationService} from "../shared/controls-translation.service";  @Component({
    moduleId: module.id,
    selector: "sis-combo-box",
    templateUrl: "sis-search-combo-box.component.html",
})
@ControlMetadata({
    groups: [
        {
            identity: "Controls",
            name: "Controls",
        },
    ],
    template: `%{#isreadonly}
                   <ng-container *ngIf="!%{isreadonly}">
                        <sis-combo-box [id]="'%{id}'" [isDisabled]="%{disabled}" (valueChange)="%{onChange}" [placeHolder]="%{placeHolder}" [recordDeletedPlaceHolder]="%{recordDeletedPlaceHolder}" [value]="%{field}?.getId()" class='sis-large-width'
                                       [currentLanguageId]="currentLanguageId" [hint]="%{hint}" [placeHolderDefaultValueKey]="'de.form.please_select'">
                                <sis-select-items [data]="%{dataItems}" [valueField]="'id'" [nameField]="'name'">                           
                                </sis-select-items>
                        </sis-combo-box>
                   </ng-container>
                   <ng-container *ngIf="%{isreadonly}">
                        <label *ngIf="%{field}" [id]="'%{id}'">{{actions.getClassifierName(%{field}, '%{field-category}') | async}}</label>
                        <sis-custom-label *ngIf="!%{field}" [key]="'reporting.controls.chart-designer.placeholder.noData'"></sis-custom-label>
                   </ng-container>
                %{/isreadonly}
                %{^isreadonly}
                   <sis-combo-box [id]="'%{id}'" [isDisabled]="%{disabled}" (valueChange)="%{onChange}" [placeHolder]="%{placeHolder}" [recordDeletedPlaceHolder]="%{recordDeletedPlaceHolder}" [value]="%{field}?.getId()" class='sis-large-width'
                                  [currentLanguageId]="currentLanguageId" [hint]="%{hint}" [placeHolderDefaultValueKey]="'de.form.please_select'">
                                <sis-select-items [data]="%{dataItems}" [valueField]="'id'" [nameField]="'name'">                           
                                </sis-select-items>
                   </sis-combo-box>
                %{/isreadonly}`,
    iconInfo: "fa-caret-square-o-down",
    name: "Searchable Combo box",
    defaultActions: {
        'dataItems': 'loadClassifiers-async',
        'onChange' : 'onClassifierChange',
        'recordDeletedPlaceHolder' : 'getClassifierName-async'
    },
    settings: {
        main: SearchComboBoxSettings
    },
    isFieldBound: true
})
/**
 * SearchComboBoxComponent is comboBox from its children.
 * Gets as input:
 *      id - The unique id for control. Mainly is used for automated testing.
 *      placeHolder - The place holder of comboBox.
 *      value - The value which transfers selected item.
 */
export class SearchComboBoxComponent extends ComboBoxComponent {

    private _value: any;

    /**
     * Theis input is not used in material implementation.
     */
    @Input()
    public height: number = 340;

    @Input('value')
    public set value(value: any) {
        this._value = value;
        this.changeDisplayValues();
    }

    public get value() {
        return this._value;
    }

    @Output()
    public valueChange: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild(MatMenuTrigger)
    private matMenuTrigger: MatMenuTrigger;

    public displayValue: string = null;

    @ViewChild('displayValueSpan')
    protected displayValueSpan: ElementRef;

    public displayInputTooltip: boolean = false;

    constructor(@Optional() protected translationService: ControlsTranslationService, private changeDetectorRef: ChangeDetectorRef) {
        super(translationService);
    }

    protected onItemsChanged() {
        super.onItemsChanged();
    }

    public setSelectedDataItem(item: any): void {
        this.value = item.value;
        this.valueChange.emit(this._value);
        this.matMenuTrigger.closeMenu();
    }

    public isRemoveActive() {
        return this.value !== null && !(this.menuTrigger && this.menuTrigger.menuOpen);
    }

    public delete(event: any): void {
        this.value = null;
        event.stopPropagation();
        this.input.nativeElement.focus();
        this.valueChange.emit(this.value);
    }

    protected changeDisplayValues() {
        if (this._value === null) {
            this.displayValue = null;
        }
        else {
            let filteredValues = this.processedDataItems.filter((item: any) => {
                return item.value === this._value;
            });
            this.displayValue = filteredValues.length > 0 ? filteredValues[0].name : null;
        }

        let isInputValueLong: boolean = this.isInputValueLong();
        if (this.displayInputTooltip !== isInputValueLong) {
            this.changeDetectorRef.markForCheck();
        }
        this.displayInputTooltip = isInputValueLong;

    }

    protected onEnterClicked() {
        this.filteredItems.length > 0 ? this.setSelectedDataItem(this.filteredItems[0]) : this.setSelectedDataItem({value: null});
    }


    /**
     * Determines whether tooltip for input should be shown or not
     */
    protected isInputValueLong(): boolean {
        if (this.displayValueSpan && this.input) {
            // 32 in this expression is value of padding
            return this.displayValueSpan.nativeElement.offsetWidth > this.input.nativeElement.clientWidth - 32;
        }
        return false;
    }
}
