/**
 * Created by vahe.marikyan on 9/19/2016.
 */
import {Component, EventEmitter, Input, Optional, Output, ViewChild} from "@angular/core";
import {ControlMetadata} from "@synisys/idm-dynamic-controls-metadata";
import {ComboBoxComponent} from "./sis-combo-box.component";
import {MatMenuTrigger} from "@angular/material";
import {MultiSelectComboBoxSettings} from "../control-settings/sis-multiselect-combo-box.settings";
import './sis-multiselect-combo-box.component.css';
import {ControlsTranslationService} from "../shared/controls-translation.service";

@Component({
    moduleId: module.id,
    selector: "sis-multiselect-combo-box",
    templateUrl: "sis-multiselect-combo-box.component.html"
})
@ControlMetadata({
    groups: [
        {
            identity: "Controls",
            name: "Controls",
        },
    ],
    template: `%{#isreadonly}
                   <ng-container *ngIf="!%{isreadonly}">
                        <sis-multiselect-combo-box [id]="'%{id}'" [isDisabled]="%{disabled}" [placeHolder]="%{placeHolder}" [recordDeletedPlaceHolder]="%{recordDeletedPlaceHolder}" [values]="%{field}" (valuesChange)="%{onChange}" [placeHolderDefaultValueKey]="'de.form.please_select'">
                            <sis-select-items [data]="%{dataItems}" [valueField]="'id'" [nameField]="'name'"></sis-select-items>
                        </sis-multiselect-combo-box>
                   </ng-container>
                   <ng-container *ngIf="%{isreadonly}">
                        <label *ngIf="%{field}" [id]="'%{id}'">{{%{field} | multiselect : 'name' : 'id' : (%{dataItems})}}</label>
                        <sis-custom-label *ngIf="!%{field}" [key]="'reporting.controls.chart-designer.placeholder.noData'"></sis-custom-label>
                   </ng-container>
               %{/isreadonly}
               %{^isreadonly}
                   <sis-multiselect-combo-box [id]="'%{id}'" [isDisabled]="%{disabled}" [placeHolder]="%{placeHolder}" [recordDeletedPlaceHolder]="%{recordDeletedPlaceHolder}" [values]="%{field}" (valuesChange)="%{onChange}" [placeHolderDefaultValueKey]="'de.form.please_select'">
                           <sis-select-items [data]="%{dataItems}" [valueField]="'id'" [nameField]="'name'"></sis-select-items>
                   </sis-multiselect-combo-box>
               %{/isreadonly}`,
    iconInfo: "fa-list-alt",
    name: "Multi Select Combo box",
    defaultActions: {
        'dataItems': 'loadClassifiers-async',
        'onChange' : 'onClassifierChange',
        'recordDeletedPlaceHolder' : 'getClassifierName-async'
    },
    settings: {
        main: MultiSelectComboBoxSettings
    },
    isFieldBound: true
})
/**
 * MultiSelectComboBox is multi select comboBox from its children.
 * Gets as input:
 *      id - The unique id for control. Mainly is used for automated testing.
 *      placeHolder - The place holder of multi select.
 *      values - The array of values which transfers selected items.
 */
export class MultiSelectComboBox extends ComboBoxComponent {
    private _values: Array<any>;

    @Input('values')
    public set values(values: Array<any>) {
        this._values = values ? values : [];
        this.changeDisplayValues();
    }

    public get values() {
        return this._values;
    }

    @Output() valuesChange: EventEmitter<Array<any>> = new EventEmitter<Array<any>>();

    @ViewChild(MatMenuTrigger)
    public matMenuTrigger: MatMenuTrigger;

    public displayValues: Array<any>;

    constructor(@Optional() protected translationService: ControlsTranslationService) {
        super(translationService);
    }

    public onItemClicked(value: any): void {
        let isAlreadySelected: boolean = this.values.some((selectedValue) => {return selectedValue === value});
        if(!isAlreadySelected) {
            this.values = [...this.values, value];
            this.valuesChange.emit(this.values);
        }
        else {
            this.values = this.values.filter((selectedValue) => {return selectedValue !== value});
            this.valuesChange.emit(this.values);
        }

        this.matMenuTrigger.closeMenu();
    }

    public deleteValue(event: any, value: any): void {
        this.values = this.values.filter((selectedValue) => {return selectedValue !== value});
        event.stopPropagation();
        this.input.nativeElement.focus();
        this.valuesChange.emit(this.values);
    }

    public deleteAll(event: any): void {
        this.values = [];
        event.stopPropagation();
        this.input.nativeElement.focus();
        this.valuesChange.emit(this.values);
    }

    public isRemoveActive() {
        return this.values.length > 0 && !(this.menuTrigger && this.menuTrigger.menuOpen);
    }

    public isSelected(value: any) {
        for(let selectedValue of this.values) {
            if(selectedValue === value) {
                return true;
            }
        }
        return false;
    }

    protected changeDisplayValues() {
        let displayValues: Array<any> = [];
        this.processedDataItems.forEach((item: any) => {
            this.values.forEach((selectedValue: any, index: number) => {
                if(selectedValue === item.value) {
                    displayValues[index] = item;
                }
            });
        });
        this.displayValues = displayValues;
    }

    protected onEnterClicked() {
        this.filteredItems.length > 0 && this.onItemClicked(this.filteredItems[0].value);
    }
}
