import {EmbeddedViewRef, IterableChangeRecord, IterableChanges, ViewContainerRef} from '@angular/core';
import {
	_ViewRepeater,
	_ViewRepeaterItemChanged, _ViewRepeaterItemContext,
	_ViewRepeaterItemContextFactory,
	_ViewRepeaterItemInsertArgs,
	_ViewRepeaterItemValueResolver,
	_ViewRepeaterOperation
} from './view-repeater';

/**
 * A repeater that caches views when they are removed from a
 * {@link ViewContainerRef}. When new items are inserted into the container,
 * the repeater will reuse one of the cached views instead of creating a new
 * embedded view. Recycling cached views reduces the quantity of expensive DOM
 * inserts.
 *
 * @template T The type for the embedded view's $implicit property.
 * @template R The type for the item in each IterableDiffer change record.
 * @template C The type for the context passed to each embedded view.
 */
export class _RecycleViewRepeaterStrategy<T, R, C extends _ViewRepeaterItemContext<T>>
	implements _ViewRepeater<T, R, C> {
	/**
	 * The size of the cache used to store unused views.
	 * Setting the cache size to `0` will disable caching. Defaults to 20 views.
	 */
	viewCacheSize: number = 20;

	/**
	 * View cache that stores embedded view instances that have been previously stamped out,
	 * but don't are not currently rendered. The view repeater will reuse these views rather than
	 * creating brand new ones.
	 */
	private _viewCache: EmbeddedViewRef<C>[] = [];

	/** Apply changes to the DOM. */
	applyChanges(changes: IterableChanges<R>,
		     viewContainerRef: ViewContainerRef,
		     itemContextFactory: _ViewRepeaterItemContextFactory<T, R, C>,
		     itemValueResolver: _ViewRepeaterItemValueResolver<T, R>,
		     itemViewChanged?: _ViewRepeaterItemChanged<R, C>) {
		// Rearrange the views to put them in the right location.
		changes.forEachOperation((record: IterableChangeRecord<R>,
					  adjustedPreviousIndex: number | null,
					  currentIndex: number | null) => {
			let view: EmbeddedViewRef<C> | undefined;
			let operation: _ViewRepeaterOperation;
			if (record.previousIndex == null) {  // Item added.
				const viewArgsFactory = () => itemContextFactory(
					record, adjustedPreviousIndex, currentIndex);
				view = this._insertView(viewArgsFactory, currentIndex!, viewContainerRef,
					itemValueResolver(record));
				operation = view ? _ViewRepeaterOperation.INSERTED : _ViewRepeaterOperation.REPLACED;
			} else if (currentIndex == null) {  // Item removed.
				this._detachAndCacheView(adjustedPreviousIndex!, viewContainerRef);
				operation = _ViewRepeaterOperation.REMOVED;
			} else {  // Item moved.
				view = this._moveView(adjustedPreviousIndex!, currentIndex!, viewContainerRef,
					itemValueResolver(record));
				operation = _ViewRepeaterOperation.MOVED;
			}

			if (itemViewChanged) {
				itemViewChanged({
					context: view && view.context,
					operation,
					record,
				});
			}
		});
	}

	detach() {
		for (const view of this._viewCache) {
			view.destroy();
		}
	}

	/**
	 * Inserts a view for a new item, either from the cache or by creating a new
	 * one. Returns `undefined` if the item was inserted into a cached view.
	 */
	private _insertView(viewArgsFactory: () => _ViewRepeaterItemInsertArgs<C>, currentIndex: number,
			    viewContainerRef: ViewContainerRef,
			    value: T): EmbeddedViewRef<C> | undefined {
		let cachedView = this._insertViewFromCache(currentIndex!, viewContainerRef);
		if (cachedView) {
			cachedView.context.$implicit = value;
			return undefined;
		}

		const viewArgs = viewArgsFactory();
		return viewContainerRef.createEmbeddedView(
			viewArgs.templateRef, viewArgs.context, viewArgs.index);
	}

	/** Detaches the view at the given index and inserts into the view cache. */
	private _detachAndCacheView(index: number, viewContainerRef: ViewContainerRef) {
		const detachedView = this._detachView(index, viewContainerRef);
		this._maybeCacheView(detachedView, viewContainerRef);
	}

	/** Moves view at the previous index to the current index. */
	private _moveView(adjustedPreviousIndex: number, currentIndex: number,
			  viewContainerRef: ViewContainerRef, value: T): EmbeddedViewRef<C> {
		const view = viewContainerRef.get(adjustedPreviousIndex!) as
			EmbeddedViewRef<C>;
		viewContainerRef.move(view, currentIndex);
		view.context.$implicit = value;
		return view;
	}

	/**
	 * Cache the given detached view. If the cache is full, the view will be
	 * destroyed.
	 */
	private _maybeCacheView(view: EmbeddedViewRef<C>, viewContainerRef: ViewContainerRef) {
		if (this._viewCache.length < this.viewCacheSize) {
			this._viewCache.push(view);
		} else {
			const index = viewContainerRef.indexOf(view);

			// The host component could remove views from the container outside of
			// the view repeater. It's unlikely this will occur, but just in case,
			// destroy the view on its own, otherwise destroy it through the
			// container to ensure that all the references are removed.
			if (index === -1) {
				view.destroy();
			} else {
				viewContainerRef.remove(index);
			}
		}
	}

	/** Inserts a recycled view from the cache at the given index. */
	private _insertViewFromCache(index: number,
				     viewContainerRef: ViewContainerRef): EmbeddedViewRef<C> | null {
		const cachedView = this._viewCache.pop();
		if (cachedView) {
			viewContainerRef.insert(cachedView, index);
		}
		return cachedView || null;
	}

	/** Detaches the embedded view at the given index. */
	private _detachView(index: number, viewContainerRef: ViewContainerRef): EmbeddedViewRef<C> {
		return viewContainerRef.detach(index) as EmbeddedViewRef<C>;
	}
}