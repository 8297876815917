import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgModule} from "@angular/core";
import {Tag} from "./tag/sis-tag.component";
import {PopupWindow} from "./popup-window/sis-popup-window.component";
import {MultiSelectComboBox} from "./combo-box/sis-multiselect-combo-box.component";
import {MultilingualInput} from "./multilingual-component/sis-multilingual-textbox.component";
import {MultilingualTextarea} from "./multilingual-component/sis-multilingual-textarea.component";
import {DatePicker} from "./date-picker/sis-date-picker.component";
import {SearchComboBoxComponent} from "./combo-box/sis-search-combo-box.component";
import {TabComponent} from "./tabs/sis-tab.component";
import {TabsComponent} from "./tabs/sis-tabs.component";
import {Tree} from "./tree/sis-tree.component";
import {TreeNode} from "./tree/sis-tree-node.component";
import {TreeNodeExpand} from "./tree/sis-tree-node-expand.component";
import {TreeNodeCollapse} from "./tree/sis-tree-node-collapse.component";
import {TreeNodeExpandCollapse} from "./tree/sis-tree-node-expand-collapse.component";
import {ValidationErrorMessage} from "./validation-error-message/sis-error.component";
import {ListPaging} from "./table/sis-list-paging.component";
import {Paging} from "./table/sis-paging.component";
import {ListSorting} from "./table/sis-list-sorting.component";
import {SortingButton} from "./table/sis-list-sorting-button.component";
import {SortingButtonAsc} from "./table/sis-list-sorting-button-asc.component";
import {SortingButtonDesc} from "./table/sis-list-sorting-button-desc.component";
import {SortingButtonUnsorted} from "./table/sis-list-sorting-button-unsorted.component";
import {Range} from "./range/sis-range.component";
import {ComboBoxItemDirective} from "./combo-box/sis-combo-box-item.directive";
import {MenuItemDirective} from "./menu/sis-menu-item.directive";
import {MenuComponent} from "./menu/sis-menu.component";
import {MainNavigationTabsComponent} from "./tabs/sis-tabs-main-navigation.component";
import {NavTabsComponent} from "./tabs/sis-nav-tabs.component";
import {DropDownButtonComponent} from "./menu/sis-dropdown-button.component";
import {SelectItemDirective} from "./shared/sis-select-item.directive";
import {PanelComponent} from "./panel/sis-panel.component";
import {TimePickerComponent} from "./time-picker/sis-time-picker.component";
import {ProgressSpinnerComponent} from "./progress-spinner/sis-progress-spinner.component";
import {MaterialDesignLiteUpgradeDirective} from "./sis-mdl/material-design-lite-upgrade.directive";
import {MaterialModule} from './material.module';
import {DateAdapter} from "@angular/material";
import {CustomDateAdapter} from "./date-picker/custom-date-adapter";
import {ColorPickerComponent} from "./color-picker/sis-color-picker.component";
import {MultiselectTransformPipe} from "./combo-box/pipes/multiselect.transform.pipe";
import {SearchableTransformPipe} from "./combo-box/pipes/searchable.transform.pipe";
import {InformationBoxComponent} from "./information-box/sis-information-box.component";
import {NotificationBoxComponent} from "./notification-box/sis-notification-box.component";
import {NumberInputDirective} from "./time-picker/directive/number-input.directive";
import {TooltipSettingsComponent} from "./control-settings/tooltip/sis-tooltip-settings.component";
import {ComboBoxSettings} from "./control-settings/sis-combo-box.settings";
import {DatePickerSettings} from "./control-settings/sis-date-picker.settings";
import {MultilingualTextareaSettings} from "./control-settings/sis-multilingual-textarea.settings";
import {MultilingualTextboxSettings} from "./control-settings/sis-multilingual-textbox.settings";
import {MultiSelectComboBoxSettings} from "./control-settings/sis-multiselect-combo-box.settings";
import {SearchComboBoxSettings} from "./control-settings/sis-search-combo-box.settings";
import {DateRangePickerComponent} from "./date-picker/sis-date-range-picker.component";
import {RadioButtonsComponent} from "./radio-buttons/sis-radio-buttons.component";
import {SisHintSettingsComponent} from "./control-settings/hint/sis-hint-settings.component";
import {SisCharacterCounterSettingsComponent} from "./control-settings/character-counter/sis-character-counter-settings.component";
import {SisControlLabelSettingsComponent} from "./control-settings/control-label/sis-control-label-settings.component";
import {ReportingComboboxComponent} from "./reporting-combo-box/reporting-combobox.component";
import {FontStyleSelectorComponent} from "./font-style-selector/sis-font-style-selector.component";
import {CustomLabelComponent} from "./custom-label/sis-custom-label.component";
import {ChipGroup} from "./chip/sis-chip-group.component";
import {DragulaModule} from "ng2-dragula";
import {ImageSelectComponent} from "./image-select/sis-image-select.component";
import {SisSelectOptionComponent} from "./sis-select/sis-select-option/sis-select-option.component";
import {SisSelectGroupOptionComponent} from "./sis-select/sis-select-group-option/sis-select-group-option.component";
import {SisSelectComponent} from "./sis-select/sis-select.component";
import {TimePickerMinutesComponent} from "./time-picker/sis-time-picker-minutes.component";
import {SisIconUpload} from "./icon-upload/sis-icon-upload.component";
import {MultilingualCopy} from "./multilingual-component/sis-multilingual-copy/sis-multilingual-copy.component";
import {MultilingualStringPipe} from "./multilingual-component/pipes/multilingual-string.pipe";
import {FieldTitlePipe} from "./multilingual-component/pipes/field-title.pipe";
import {CopyLanguageCacheService} from "./shared/copy-language-cache.service";
import {SisNgTooltipComponent} from "./tooltip/sis-ng-tooltip.component";
import {OverlayModule} from "@angular/cdk/overlay";
import {SisTooltipDirective} from "./tooltip/sis-tooltip.directive";
import {ScrollingModule} from "./virtual-scroller/scrolling.module";


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MaterialModule,
        ReactiveFormsModule,
        ScrollingModule,
        DragulaModule,
        OverlayModule
    ],
    declarations: [
        Tag,
        PopupWindow,
        MultiSelectComboBox,
        MultilingualInput,
        MultilingualTextarea,
        MultilingualCopy,
        MultilingualStringPipe,
        FieldTitlePipe,
        DatePicker,
        DateRangePickerComponent,
        SelectItemDirective,
        ComboBoxItemDirective,
        SearchComboBoxComponent,
        TabComponent,
        TabsComponent,
        Tree,
        TreeNode,
        TreeNodeExpand,
        TreeNodeCollapse,
        TreeNodeExpandCollapse,
        ValidationErrorMessage,
        TreeNodeExpandCollapse,
        ListPaging,
        Paging,
        ListSorting,
        SortingButton,
        SortingButtonAsc,
        SortingButtonDesc,
        SortingButtonUnsorted,
        Range,
        MenuItemDirective,
        MenuComponent,
        MainNavigationTabsComponent,
        NavTabsComponent,
        DropDownButtonComponent,
        PanelComponent,
        TimePickerComponent,
        TimePickerMinutesComponent,
        ProgressSpinnerComponent,
        MaterialDesignLiteUpgradeDirective,
        ColorPickerComponent,
        MultiselectTransformPipe,
        SearchableTransformPipe,
        InformationBoxComponent,
        NotificationBoxComponent,
        DateRangePickerComponent,
        NumberInputDirective,
        TooltipSettingsComponent,
        SisHintSettingsComponent,
        ComboBoxSettings,
        DatePickerSettings,
        MultilingualTextareaSettings,
        MultilingualTextboxSettings,
        MultiSelectComboBoxSettings,
        SearchComboBoxSettings,
        RadioButtonsComponent,
        SisCharacterCounterSettingsComponent,
        SisControlLabelSettingsComponent,
        ReportingComboboxComponent,
        FontStyleSelectorComponent,
        CustomLabelComponent,
        ChipGroup,
        ImageSelectComponent,
        SisSelectComponent,
        SisSelectOptionComponent,
        SisSelectGroupOptionComponent,
        SisIconUpload,
        SisNgTooltipComponent,
        SisTooltipDirective
    ],
    exports: [
        Tag,
        PopupWindow,
        MultiSelectComboBox,
        MultilingualInput,
        MultilingualTextarea,
        DatePicker,
        DateRangePickerComponent,
        SelectItemDirective,
        ComboBoxItemDirective,
        SearchComboBoxComponent,
        TabComponent,
        TabsComponent,
        Tree,
        TreeNode,
        TreeNodeExpand,
        TreeNodeCollapse,
        TreeNodeExpandCollapse,
        ValidationErrorMessage,
        TreeNodeExpandCollapse,
        ListPaging,
        Paging,
        ListSorting,
        SortingButton,
        SortingButtonAsc,
        SortingButtonDesc,
        SortingButtonUnsorted,
        Range,
        MenuItemDirective,
        MenuComponent,
        MainNavigationTabsComponent,
        NavTabsComponent,
        DropDownButtonComponent,
        PanelComponent,
        TimePickerComponent,
        TimePickerMinutesComponent,
        ProgressSpinnerComponent,
        ColorPickerComponent,
        MultiselectTransformPipe,
        SearchableTransformPipe,
        InformationBoxComponent,
        NotificationBoxComponent,
        DateRangePickerComponent,
        NumberInputDirective,
        TooltipSettingsComponent,
        SisHintSettingsComponent,
        ComboBoxSettings,
        DatePickerSettings,
        MultilingualTextareaSettings,
        MultilingualTextboxSettings,
        MultiSelectComboBoxSettings,
        SearchComboBoxSettings,
        RadioButtonsComponent,
        SisCharacterCounterSettingsComponent,
        SisControlLabelSettingsComponent,
        ReportingComboboxComponent,
        FontStyleSelectorComponent,
        CustomLabelComponent,
        ChipGroup,
        ImageSelectComponent,
        SisSelectComponent,
        SisSelectOptionComponent,
        SisSelectGroupOptionComponent,
        SisIconUpload,
        SisNgTooltipComponent,
        SisTooltipDirective
    ],
    providers: [
        {provide: DateAdapter, useClass: CustomDateAdapter},
        CopyLanguageCacheService
    ],
    entryComponents: [SisNgTooltipComponent]
})
export class ControlsModule {
}

export * from "./popup-window/sis-popup-window.component";
export * from "./panel/panel-placement";
export * from "./sis-mdl/material-design-lite-upgrade.directive";
export * from "./combo-box/sis-combo-box.component";
export * from "./control-settings/index"
export * from "./shared/radio-button-item";
export * from "./shared/controls-translation.service";
export * from "./shared/font-style";
export * from "./reporting-combo-box/shared/index";
export * from "./shared/message.service";
export * from "./sis-select/sis-select-group-option/sis-select-group-option.component";
export * from "./sis-select/sis-select-option/sis-select-option.component";
export * from "./sis-select/sis-select.component";
export * from "./sis-select/event/selection-change";
export * from "./tooltip/tooltip-configs.model";
export * from './virtual-scroller/fixed-size-virtual-scroll/fixed-size-virtual-scroll';
export * from './virtual-scroller/scroll-dispatcher/scroll-dispatcher';
export * from './virtual-scroller/scrollable/scrollable';
export * from './virtual-scroller/viewport-ruler/viewport-ruler';
export * from './virtual-scroller/virtual-for-of/virtual-for-of';
export * from './virtual-scroller/virtual-scroll-strategy/virtual-scroll-strategy';
export * from './virtual-scroller/virtual-scroll-viewport/sis-virtual-scroll-viewport.component';
export * from './virtual-scroller/virtual-scroll-repeater/virtual-scroll-repeater';
