import {
    AfterViewChecked,
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';

import {ControlMetadata} from '@synisys/idm-dynamic-controls-metadata';
import {Language, MultilingualString} from '@synisys/idm-crosscutting-concepts-frontend';
import {TooltipSettings} from "./tooltip-settings.model";
import {ControlValueAccessor} from "@angular/forms";
import './sis-tooltip-settings.component.css';

@Component({
    moduleId: module.id + '',
    selector: 'sis-ng-tooltip-settings',
    templateUrl: 'sis-tooltip-settings.component.html',
})
@ControlMetadata({
    template: `<sis-ng-tooltip-settings
                    [settings]="%{tooltipSettings}"
                    [languages]="%{languages}"
                    [currentLanguageId]="%{currentLanguageId}">
               </sis-ng-tooltip-settings>`,
})
export class TooltipSettingsComponent implements OnInit, AfterViewInit, AfterViewChecked {
    get isReady(): boolean {
        return this._isReady;
    }

    set isReady(value: boolean) {
        this._isReady = value;
    }

    @Input()
    private settings: TooltipSettings;

    @Input()
    private languages: Language[];

    @Input()
    private currentLanguageId: number;

    private _isReady = false;

    @ViewChild('isWithTooltip')
    private isWithTooltipCheckbox: ControlValueAccessor;

    @ViewChild('caption')
    private captionComponent: ControlValueAccessor;

    @ViewChild('content')
    private contentComponent: ControlValueAccessor;

    @Output()
    private settingsChange: EventEmitter<TooltipSettings> = new EventEmitter<TooltipSettings>();

    constructor() {
    }

    public ngOnInit(): void {
        if (this.settings === undefined) {
            this.settings = TooltipSettings.createEmptyFrom(this.languages);
        }
        this._isReady = true;
    }

    public ngAfterViewInit(): void {
        this.isWithTooltipCheckbox.registerOnChange((value: boolean) => {
            this.settings.isWithTooltip = value;
            this.settingsChange.emit(value ? this.settings : new TooltipSettings(false));
        });
    }

    public ngAfterViewChecked(): void {
        if (this.settings.isWithTooltip) {
            this.captionComponent === undefined
            || this.captionComponent.registerOnChange((value: MultilingualString) => {
                this.settings.caption = value;
                this.settingsChange.emit(this.settings);
            });
            this.contentComponent === undefined
            || this.contentComponent.registerOnChange((value: MultilingualString) => {
                this.settings.content = value;
                this.settingsChange.emit(this.settings);
            });
        }
    }
}
