/**
 * @author Vahagn Lazyan.
 */
import {Component} from "@angular/core";

import {BaseSettings} from "./sis-base-settings";
import {CommunicationService, ControlMetadata} from "@synisys/idm-dynamic-controls-metadata";

@Component({
    moduleId: module.id + '',
    selector: "sis-combo-box-settings",
    template: `
        <sis-ng-tooltip-settings [(settings)]="tooltipSettings" [languages]="languages" [currentLanguageId]="currentLanguageId">
        </sis-ng-tooltip-settings>
        <sis-ng-control-label-settings [languages]="languages" [currentLanguageId]="currentLanguageId"
                                       [(titleModel)]="controlLabel">
        </sis-ng-control-label-settings>`
})
@ControlMetadata({
    template: `<sis-combo-box-settings [state]="%{state}" [languages]="%{languages}" 
                                       [currentLanguageId]="%{currentLanguageId}" 
                                       [controlLabel]="%{controlLabel}">
                </sis-combo-box-settings>`
})
export class ComboBoxSettings extends BaseSettings {
    constructor(communicationService: CommunicationService) {
        super(communicationService);
    }
}
