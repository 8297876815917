import {Component, EventEmitter, Input, OnInit, Output, SimpleChange, SimpleChanges} from "@angular/core";
import {DragulaService} from "ng2-dragula";
import {createListItem, filterGroupedData, initComboBoxOptions, initComboOptions} from "../helpers/combo-item-processing-helper";


/**
 * Chip group controls that will render the passed items as chip list or chip tree.
 * This control will have the possibility to change the order of these elements.
 */
import "./sis-chip-group.component.css"; @Component({
    moduleId: module.id,
    selector: "sis-chip-group-combo",
    templateUrl: "sis-chip-group.component.html",
    providers: [DragulaService]
})
export class ChipGroup implements OnInit {

    /**
     * Unique id for chip group
     * @type {string}
     */
    @Input()
    public id: string;

    /**
     * The text that will be rendered when the item is empty
     * @type {string}
     */
    @Input()
    public placeholder: string;

    /**
     * The max count of elements that can be rendered in the group
     * @type {number}
     */
    @Input()
    public maxCount: number;

    /**
     * The list of elements that should be rendered as chip list(tree)
     * @type {Array<any>}
     */
    @Input()
    public items: Array<any>;

    @Input()
    public set data(data: Array<any>) {
        this._data = data.slice();
        this.filteredData = this._data;

        this.allAvailableItems = initComboBoxOptions(this._data);
        this.selectedItems && (this.draggableItems = this.processItems());
    }

    /**
     * The name field of the passed items
     * @type {string}
     */
    @Input()
    public nameField: string;

    /**
     * The id field of the passed items
     * @type {string}
     */
    @Input()
    public idField: string;

    /**
     * The color field of the passed items
     * @type {string}
     */
    @Input()
    public colorField: string;

    /**
     * Defines if the tags should be rendered as tree or list
     * @type {boolean}
     */
    @Input()
    public isHierarchical: boolean;

    /**
     * The width of chip elements
     * @type {number}
     */
    @Input()
    public width: number = 150;

    /**
     * Defines the indent of the next level according to previous level
     * @type {number}
     */
    @Input()
    public indent: number = 10;


    @Input()
    public hasColor: boolean;

    /**
     * Indicates if the last chip us removable or not
     * @type {boolean}
     * @default true
     */
    @Input()
    public isLastRemovable: boolean = true;

    /**
     * The function that will be called when the items list is changed
     * @type {EventEmitter}
     */
    @Output()
    public onItemsChange: EventEmitter<Array<any>> = new EventEmitter<Array<any>>();

    @Output()
    public onSelectedItemsChange: EventEmitter<Array<number>> = new EventEmitter<Array<number>>();

    public clickedItemIndex: number = -1;

    public draggableItems: Array<any> = null;

    public isComboOpened: boolean;

    public currentValue: number;

    public filteredData: Array<any>;

    private _data: Array<any>;

    private selectedItems: Array<any>;

    public allAvailableItems: Array<any> = [];

    public constructor(private dragulaService: DragulaService) {
    }

    public ngOnInit(): void {
        this.dragulaService.drop.subscribe((value: any) => {
            this.onItemsChange.emit(this.chipsItemsToIds());
        });
    }

    /**
     * This method will handle all input changes
     * @param {SimpleChange} changes
     * @returns {void}
     * @public
     * @method
     */
    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['items'] && this.selectedItems !== this.items) {
            this.selectedItems = this.items;
            this.draggableItems = this.processItems();
        }
    }

    public delete(id: any): void {
        let updatedMeasures: Array<number> = this.selectedItems.filter((measureId: number) => {
            return measureId !== id;
        });

        this.items = updatedMeasures;
        this.selectedItems = this.items;
        this.onSelectedItemsChange.emit(this.selectedItems);

        this.draggableItems = this.draggableItems.filter((value: any) => {
            return value[this.idField] !== id;
        });
    }

    public click(index: number, id: any): void {
        this.clickedItemIndex = index;
        this.currentValue = id;
        this.filteredData = filterGroupedData(this._data, this.currentValue, this.selectedItems);

        this.filteredData.length > 0 && (this.isComboOpened = true);
    }

    public closeCombobox(isClosed: boolean): void {
        if (isClosed) {
            this.isComboOpened = false;
        }
    }

    public setValue(value: any): void {
        if (value) {
            let selectedValuesIds: any[] = this.selectedItems.slice();
            let selectedValues: any[] = this.draggableItems.slice();
            let path: any = createListItem(value);

            let selectedMeasureIndex: number = this.currentValue != null ? selectedValuesIds.indexOf(this.currentValue) : selectedValuesIds.indexOf(path.id);
            selectedMeasureIndex !== -1 ? selectedValuesIds[selectedMeasureIndex] = path.id : selectedValuesIds.push(path.id);
            selectedMeasureIndex !== -1 ? selectedValues[selectedMeasureIndex] = path : selectedValues.push(path);

            this.items = selectedValuesIds;
            this.selectedItems = this.items;
            this.onSelectedItemsChange.emit(this.selectedItems);

            this.draggableItems = selectedValues;
        }
    }

    private processItems() {
        let processedData: Array<any> = initComboOptions(this.selectedItems, this.allAvailableItems);

        return processedData.map((value: any) => {
            return createListItem(value);
        });
    }

    private chipsItemsToIds() {
        return this.draggableItems.map((item: any) => {
            return item[this.idField];
        })
    }
}
